import { useContext } from 'react';
import { useAuth } from 'hooks';
import { Tooltip } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import {
  resetFilters,
  performSearch,
  setShowVCFundingSortModal,
} from 'actions/search';
import {
  getHasValidQuery,
  getSearchRequest,
  getSearchView,
} from 'selectors/search';
import { Button, Pane, Icon } from 'components/common';
import { colours } from 'utils/theme';
import { DisabledButtonWrapper } from 'components/common/styled.js';
import licenseType from 'models/licenseType';

import Geo from './Geo';
import Funding from './Funding';
import Tradeshows from './Tradeshows';
import Trials from './Trials';
import Advanced from './Advanced';
import Lists from './Lists';
import { Container, Content, Actions } from './styled';
import { SearchContext } from '../';
import VentureCapital from './VentureCapital';
import { ResearcherSearch } from '../Results/helpers';
import Acquisitions from './Acquisitions';
import { useCanShowVCFundingSortModal } from '../FundingSortModal/fundingSortModal';

const Help = () => (
  <Tooltip
    title='Search filters can help you easily refine your search to better target the results you are after.'
    placement='right'
  >
    <div style={{ cursor: 'pointer' }}>
      <Icon icon='help' color={colours.teal} size='xs' />
    </div>
  </Tooltip>
);

const Filters = () => {
  const dispatch = useDispatch();
  const { postSearch, postGeoSearch } = useContext(SearchContext);
  const { loading } = useSelector(getSearchRequest);
  const canShowVCFundingSortModal = useCanShowVCFundingSortModal();
  const { hasLicense } = useAuth();
  const hasIndustryLicense = hasLicense([licenseType.industry], true);

  const handleReset = () => {
    dispatch(resetFilters);
  };

  const handleSearch = () => {
    if (canShowVCFundingSortModal) {
      dispatch(setShowVCFundingSortModal(true));
    } else {
      dispatch(performSearch(postSearch, postGeoSearch));
    }
  };

  const hasValidQuery = useSelector(getHasValidQuery);

  const isOrgView = !ResearcherSearch(useSelector(getSearchView));

  const updateButton = (
    <Button
      onClick={handleSearch}
      type='primary'
      style={{ marginLeft: '10px' }}
      disabled={!hasValidQuery || loading}
      data-testid='filter-update'
    >
      Search
    </Button>
  );

  return (
    <Container>
      <Pane title='Search Filters' headerRight={<Help />}>
        <Content>
          <Geo loading={loading} />
          <Funding loading={loading} />
          {isOrgView && hasIndustryLicense && (
            <VentureCapital loading={loading} />
          )}
          {isOrgView && hasIndustryLicense && (
            <Acquisitions loading={loading} />
          )}
          <Tradeshows loading={loading} />
          <Trials loading={loading} />
          <Lists loading={loading} />
          <Advanced loading={loading} />
        </Content>
        <Actions>
          <Button
            data-testid='filter-reset'
            onClick={handleReset}
            disabled={loading}
          >
            Reset
          </Button>
          {!hasValidQuery || loading ? (
            <Tooltip title='Enter search criteria'>
              <DisabledButtonWrapper>{updateButton}</DisabledButtonWrapper>
            </Tooltip>
          ) : (
            updateButton
          )}
        </Actions>
      </Pane>
    </Container>
  );
};

export default Filters;
