import { useState, useRef } from 'react';

import { Input, Tooltip } from 'components/common';

import Options from './Options';
import { Container } from './styled';

const Typeahead = ({
  placeholder,
  options,
  value = '',
  onChange = () => {},
  className,
  optionKey,
  displayKey,
  helpText,
  'data-testid': dataTestId = 'typeahead',
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const ref = useRef(null);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = e => {
    setIsFocused(false);
  };

  const focusInput = () => {
    ref.current.focus();
  };

  const handleOptionClick = value => {
    onChange(value);
    handleBlur();
    ref.current.blur();
  };

  return (
    <Tooltip title={helpText} placement='right'>
      <Container onClick={focusInput} className={className}>
        <Input
          data-testid={dataTestId}
          onBlur={handleBlur}
          onChange={onChange}
          onFocus={handleFocus}
          value={value}
          placeholder={!value.length ? placeholder : ''}
          ref={ref}
        />
        {options && isFocused && (
          <Options
            options={options}
            filterTerm={value}
            onOptionClick={handleOptionClick}
            optionKey={optionKey}
            displayKey={displayKey}
          />
        )}
      </Container>
    </Tooltip>
  );
};

export default Typeahead;
