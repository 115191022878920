import { createSelector } from 'reselect';
import { ResearcherSearch } from 'components/Search/Results/helpers';

export const getFilter = ({ filters }, id, group) => {
  if (group && filters[group]) {
    return filters[group][id];
  }
  return filters[id];
};

export const getFiltersByGroupId = ({ filters }, group) => {
  if (group && filters[group]) {
    return filters[group];
  }
  return null;
};
export const getHasResults = ({
  view,
  searchRequest: { res, orgRes, loading },
}) => {
  if (!ResearcherSearch(view)) {
    return (
      !loading && Object.keys(orgRes).length > 0 && orgRes.totalResults > 0
    );
  }

  return !loading && Object.keys(res).length > 0 && res.totalResults > 0;
};

export const getHasValidQuery = ({ filters, view }) => {
  const tradeshowCheckboxSelectedForOrgView = !(
    !ResearcherSearch(view) &&
    filters.tradeshow &&
    !(
      filters.thisShowOnly ||
      filters.exhibitingShow ||
      filters.presentingAtTradeshow
    )
  );

  return (
    tradeshowCheckboxSelectedForOrgView &&
    filters.category &&
    Object.values(filters.category).filter(c => c).length > 0
  );
};

export const getListsRequest = createSelector(
  s => s.listsRequest,
  req => ({
    res: req.res || [],
    loading: req.loading,
    error: req.error,
  })
);

export const getSearchRequest = state => {
  return {
    res: ResearcherSearch(state.view)
      ? state.searchRequest.res || {}
      : state.searchRequest.orgRes || {},
    loading: state.searchRequest.loading,
    error: state.searchRequest.error,
  };
};

export const getGeoSearchData = createSelector(
  [s => s.view, s => s.geoSearchData],
  (view, req) => (ResearcherSearch(view) ? req.res : req.orgRes) || {}
);

export const getHasFilters = (state, keys) => {
  const currentKeys = Object.keys(state.filters);
  return keys.find(key => currentKeys.includes(key));
};

export const getActiveFilters = (state, keys) => {
  const currentKeys = Object.keys(state.filters);
  return keys.filter(key => currentKeys.includes(key));
};

export const getShowRefreshDialog = state => state.showRefreshDialog;

export const getNextResultsForRefresh = state => state.nextResults;

export const getExternalSearch = state => state.externalSearch;

export const getLastSuccessfulSearch = state => state.lastSuccessfulSearch;

export const isPdfExportRunning = state => state.isPdfExportRunning;

export const getSortBy = state => state.sortBy;

export const getOrgSortBy = state => state.orgSortBy;

export const getSearchView = state => state.view;

export const getFilters = state => state.filters;

export const getRestoringState = state => state.restoringState;

export const getShowBuilder = state => state.showBuilder;

export const getSearchBuilderFields = (state, id) =>
  state.searchBuilderFields[id] || {};

export const getResearcherIds = state => state.researcherIds;

export const getListAssignedToResearcher = state =>
  state.listAssignedToResearchers;

export const getListAssignedToResearcherAvailable = state =>
  state.listAssignedToResearcherAvailable;

export const getOrganisationIds = state => state.organisationIds;

export const getListAssignedToOrganisation = state =>
  state.listAssignedToOrganisations;

export const getBlockedForExport = state => state.blockedForExport;

export const getMapRegion = state => state.mapRegion;

export const getFilterSectionIsCollapsed = (state, sectionKey) =>
  !state.openFilterSections[sectionKey];

export const hasDissmissedVCFundingSortModal = state =>
  !!state.dismissedVCFundingSortModal;

export const getShowVCFundingSortModal = state =>
  !!state.showVCFundingSortModal;

export const getAddMembersToListFromSearchError = state =>
  state.addMembersToListFromSearchError;
